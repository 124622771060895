import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import * as Sentry from '@sentry/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'react-toggle/style.css';
import App from './containers/app';
import LoginCallback from './containers/login/callback';
import './index.css';
import './logging';
import { authenticationService, initialiseFontAwesome } from './services';

initialiseFontAwesome();
authenticationService.initialise({
  authentication: {
    default: 'SCRTrackerDB',
    enterprise: {
      cognita: 'conkaa-waad',
      oneschoolglobal: 'OneSchoolGlobal',
    },
  },
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
});
authenticationService.checkSSO();

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <BrowserRouter>
      <Switch>
        <Route exact path={'/callback'}>
          <LoginCallback />
        </Route>
        <Route>
          <App />
        </Route>
      </Switch>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
