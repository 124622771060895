export const sanitizeKey = (key: string): string => {
  if (!key) {
    throw new Error('Key not specified');
  }
  return key
    .replace(/[^a-zA-Z0-9_]/g, '_') // remove all non chars
    .toLowerCase()
    .trim()
    .replace(/_+/g, '_') // remove repeating '_'
    .replace(/^[0-9_]+/gm, '') // remove numbers and '_' from start
    .replace(/_+$/gm, ''); // remove '_' from end
};

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return s;
  if (s.length === 0) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export function replaceTokens(
  input: string,
  tokens: Record<string, any>
): string {
  if (!input) return input;

  let result = input;
  for (const key of Object.keys(tokens || {})) {
    const regextoreplace = new RegExp(`{{${key}}}`, 'g');
    result = result.replace(regextoreplace, tokens[key]);
  }

  return result;
}

export const keyGenerator = async (
  keySource: string, // : string,
  keyExistsCheck: (key: string) => Promise<boolean> | boolean
) => {
  if (typeof keySource !== 'string') {
    throw new Error('KeySource must be a String)');
  }
  let proposedKey = sanitizeKey(keySource);
  let n = 1;
  const originalKey = proposedKey;
  while (await keyExistsCheck(proposedKey)) {
    proposedKey = `${originalKey}${n++}`;
    if (n > 100) {
      throw new Error('Failing to generate Key, at iteration 100. Giving up');
    }
  }
  return proposedKey;
};

export const pascalToUnderScore = (s: string) =>
  s
    .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');

export const lowercaseFirstLetter = (s: string) =>
  s.charAt(0).toLowerCase() + s.slice(1);

export const singular = (s) => {
  if (s.charAt(s.length - 1).toLowerCase() === 's') {
    return s.slice(0, s.length - 1);
  }
  return s;
};

export const uppercaseFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

type Grouped<T> = { [key: string]: T[] };

export const group = <T>(
  items: T[],
  keyGetter: (item: T) => string
): Grouped<T> => {
  const result: Grouped<T> = {};

  for (const item of items) {
    const key = keyGetter(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
  }

  return result;
};

export const stringMask = (len: number) => {
  return '*'.repeat(len);
};
