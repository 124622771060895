import {
  Button as ButtonCore,
  ButtonProps as ButtonPropsBP,
  Popover,
  Tooltip,
} from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRunOnce } from '../../hooks';
// @ts-ignore
import styles from './styles.module.css';

function def<T>(value: T, defaultValue: T): T {
  if (value == null) return defaultValue;
  return value;
}

// TODO/
// - Confirmation
// - Error state

type ButtonProps = Omit<ButtonPropsBP, 'icon' | 'iconColor' | 'iconsize'> & {
  id?: string;
  allowed?: boolean;
  tooltip?: string;
  onClickError?: (error: unknown) => void;
  onClick: (event?: MouseEvent) => Promise<void> | void;
  icon?: any;
  iconSize?: any;
  iconColor?: any;
  confirm?: boolean;
  translate?: boolean;
  text?: any;
  children?: React.ReactNode;
};

type ButtonWithTooltipProps = ButtonProps & {
  tooltip: string | JSX.Element;
};

const ButtonWithTooltip = ({
  tooltip,
  onClick,
  translate,
  ...rest
}: ButtonWithTooltipProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      disabled={rest.disabled}
      content={def(translate, true) ? t(tooltip) : tooltip}
      popoverClassName={styles.tooltip}
    >
      <ButtonCore onClick={onClick} {...rest} />
    </Tooltip>
  );
};

const Button = ({
  allowed,
  tooltip,
  disabled,
  onClick: onClickUnProtected,
  onClickError,
  icon,
  iconColor,
  iconSize,
  confirm,
  translate,
  large,
  text,
  className,
  ...rest
}: ButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [onClick, isExecuting] = useRunOnce(onClickUnProtected, () => {
    if (!allowed || disabled) return false;
    return true;
  });

  // default allowed to true
  allowed = def(allowed, true);
  if (!allowed) {
    disabled = true;
  }

  icon = isExecuting ? 'spinner' : allowed ? icon : 'lock';
  // getting console errors about finding "null" icon
  if (icon == null) icon = undefined;

  let buttonComponent;

  if (tooltip) {
    buttonComponent = (
      <ButtonWithTooltip
        {...rest}
        className={cn(styles.buttonNoWrap, className)}
        onClick={onClick}
        disabled={disabled}
        large={large}
        icon={
          icon && (
            <FontAwesomeIcon
              spin={isExecuting}
              icon={icon}
              color={iconColor}
              size={iconSize}
            />
          )
        }
        text={def(translate, true) ? t(text) : text}
        tooltip={tooltip}
      />
    );
  } else {
    buttonComponent = (
      <ButtonCore
        {...rest}
        className={cn(styles.buttonNoWrap, className)}
        onClick={onClick}
        disabled={disabled}
        large={large}
        icon={
          icon && (
            <FontAwesomeIcon
              spin={isExecuting}
              icon={icon}
              color={iconColor}
              size={iconSize}
            />
          )
        }
        text={def(translate, true) ? t(text) : text}
      />
    );
  }

  if (confirm) {
    return (
      <Popover
        // style={{ width: '100%' }}
        isOpen={!isExecuting && isOpen}
        onClose={() => setIsOpen(false)}
      >
        {React.cloneElement(buttonComponent, {
          onClick: () => {
            if (isExecuting) return;
            setIsOpen(true);
          },
        })}
        <div className={styles.confirmAlert}>
          <p>{t('Are you sure you want to do this?')}</p>
          <div className={styles.confirmAlertButtons}>
            <ButtonCore onClick={() => setIsOpen(false)}>
              {t('Cancel')}
            </ButtonCore>
            <ButtonCore
              intent="primary"
              onClick={(event) => {
                // invoke the original with confirmation
                setIsOpen(false);
                return onClick(event);
              }}
            >
              {t('Confirm')}
            </ButtonCore>
          </div>
        </div>
      </Popover>
    );
  }

  return buttonComponent;
};

export default Button;
