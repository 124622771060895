import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../../context';
import { authenticationService, getRequest } from '../../services';

import routes from '../../routes';
import LoginContainer from '../login';
import HeaderContainer from './header';
import { Loading } from '@hogwarts/ui-components-core';
import * as Sentry from '@sentry/react';

import styles from './styles.module.css';

const App = () => {
  const isAuthenticated = authenticationService.isAuthenticated();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      getRequest().then((request) => {
        return request.get('/user').then((result) => {
          if (result.status === 200) {
            setUser(result.data);
            Sentry.setUser({
              name: result.data.name,
            });
          }
        });
      });
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <LoginContainer />;
  }
  if (!user) {
    return <Loading showLogo />;
  }

  return (
    <UserContext.Provider value={user}>
      <HeaderContainer />
      <div className={styles.appContainer}>
        <Switch>
          {routes.map((route) => (
            <Route
              key={`${route.path}-route`}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </UserContext.Provider>
  );
};

export default App;
