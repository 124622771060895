import { getEnvironment, environmentTypes } from './services';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
const packageJson = require('../package.json');

const environment = getEnvironment().environment;

Sentry.init({
  dsn: 'https://df11fa65c40d4921bff02c513769ba9f@performance.scrtracker.com/5552473',
  integrations: [new TracingIntegrations.BrowserTracing(), new SentryRRWeb()],
  tracesSampleRate: 0.2,
  release: `backup-web@${packageJson.version}`,
  environment,
  beforeSend: (event) => {
    if (environment !== environmentTypes.DEVELOPMENT) {
      return event;
    }
  },
});
