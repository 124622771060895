import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faTrashAlt,
  faPlus,
  faSearch,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';

import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export default () => {
  library.add(
    faUser,
    faInfoCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faTrashAlt,
    farTrashAlt,
    faPlus,
    faSearch,
    faQuestionCircle
  );
};
