export const isCentralTeam = (organisation: any): boolean => {
  if (!organisation) {
    return false;
  }
  const shared = organisation.attributes?.shared;
  if (typeof shared === 'string' && shared.startsWith('YES')) {
    return true;
  }
  return (
    // Legacy setting (Bool)
    shared === true
  );
};

export const DEFAULT_LOCATION = 'GB';
export const DEFAULT_TIMEZONE = 'Europe/London';
