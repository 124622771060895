import React from 'react';
import { useTranslation } from 'react-i18next';
import { Callout } from '@blueprintjs/core';

const NoOrganisations = () => {
  const { t } = useTranslation();

  return (
    <Callout
      icon="warning-sign"
      intent="warning"
      title={t('No Organisation Access')}
    >
      {t(
        "Your user doesn't seem to have access to any organisations, please check with your administrator if you think this is incorrect."
      )}
    </Callout>
  );
};

export default NoOrganisations;
