import React, { useState } from 'react';
import { Label, InputGroup, Button, Callout } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import rules from 'password-rules';

import styles from './styles.module.css';

const passwordRules = {
  minimumLength: 8,
  maximumLength: 30,
  requireCapital: true,
  requireLower: true,
  requireNumber: true,
  requireSpecial: true,
};

const PasswordResetPage = ({
  isNewUser,
  resetError,
  onResetPassword,
  onResetEnd,
}) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState({
    password: '',
    repeatedPassword: '',
  });
  const [passwordRequirements, setPasswordRequirements] = useState(null);

  if (!submitted || resetError) {
    return (
      <>
        {resetError ? (
          <Callout
            className={styles.errorMessage}
            intent="danger"
            title="Error Setting Password"
          >
            {t(resetError)}
          </Callout>
        ) : isNewUser ? (
          <p className={styles.headerMessage}>{t('Set Your Password')}</p>
        ) : (
          <p className={styles.headerMessage}>{t('Set New Password')}</p>
        )}
        <p className={styles.secondaryMessage}>
          {t(
            isNewUser
              ? 'Welcome to SCR Tracker! To complete your signup please enter a password below.'
              : 'To complete your password reset please enter a new password below.'
          )}
        </p>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onResetPassword(newPassword.password);
            setSubmitted(true);
          }}
        >
          <Label>
            <p className={styles.formLabel}>
              {t(isNewUser ? 'Password' : 'New Password')}
            </p>
            <InputGroup
              large
              autoFocus
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              value={newPassword.password}
              onChange={(e) => {
                setNewPassword({ ...newPassword, password: e.target.value });
                const passwordQuality = rules(e.target.value, passwordRules);
                if (passwordQuality && passwordQuality.sentence) {
                  setPasswordRequirements(passwordQuality.sentence);
                } else {
                  setPasswordRequirements(null);
                }
              }}
              rightElement={
                <Button
                  minimal
                  icon={showPassword ? 'unlock' : 'lock'}
                  intent="warning"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              }
            />
            {passwordRequirements && (
              <p className={styles.passwordIssue}>{t(passwordRequirements)}</p>
            )}
          </Label>
          <Label>
            <p className={styles.formLabel}>
              {t(isNewUser ? 'Repeat Password ' : 'Repeat New Password')}
            </p>
            <InputGroup
              large
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              value={newPassword.repeatedPassword}
              onChange={(e) =>
                setNewPassword({
                  ...newPassword,
                  repeatedPassword: e.target.value,
                })
              }
            />
            {newPassword.repeatedPassword &&
              newPassword.password !== newPassword.repeatedPassword && (
                <p className={styles.passwordIssue}>
                  {t('Passwords must match.')}
                </p>
              )}
          </Label>
          <div className={styles.rightButton}>
            <Button
              className="mr-1"
              large
              onClick={() => {
                onResetEnd();
              }}
            >
              {t('Go Back')}
            </Button>
            <Button
              type="submit"
              large
              intent="primary"
              disabled={
                passwordRequirements ||
                !newPassword.password ||
                newPassword.password !== newPassword.repeatedPassword
              }
            >
              {t('Set Password')}
            </Button>
          </div>
        </form>
      </>
    );
  } else {
    return (
      <>
        <p className={styles.headerMessage}>{t('Password Reset Complete')}</p>
        <p className={styles.secondaryMessage}>
          {t(
            'Your password has now been changed. Please click below to go back and login with your new password.'
          )}
        </p>
        <div className={styles.rightButton}>
          <Button large intent="primary" onClick={() => onResetEnd()}>
            {t('Log In')}
          </Button>
        </div>
      </>
    );
  }
};

export default PasswordResetPage;
