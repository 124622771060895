const DEVELOPMENT = 'development';
const STAGING = 'staging';
const PRODUCTION = 'production';

function getEnvironment() {
  const { protocol, host } = window.location;
  const lookup = {
    'scrtracker-storybook.eu.ngrok.io': {
      environment: DEVELOPMENT,
      serverUri: `${protocol}//scrtracker-api.eu.ngrok.io`,
    },
    // Development
    'localhost:3010': {
      environment: DEVELOPMENT,
      serverUri: `${protocol}//localhost:3011/api/v1`,
    },
    'backup-development.scrtracker.com': {
      environment: DEVELOPMENT,
      serverUri: `${protocol}//localhost:3011/api/v1`,
    },
    'cognita.backup-development.scrtracker.com': {
      environment: DEVELOPMENT,
      serverUri: `${protocol}//localhost:3011/api/v1`,
    },
    // Staging
    'backup-staging.scrtracker.com': {
      environment: STAGING,
      serverUri: `${protocol}//backup-api-staging.scrtracker.com`,
    },
    'cognita.backup-staging.scrtracker.com': {
      environment: STAGING,
      serverUri: `${protocol}//backup-api-staging.scrtracker.com`,
    },
    // Production
    'backup.scrtracker.com': {
      environment: PRODUCTION,
      serverUri: `${protocol}//backup-api.scrtracker.com`,
    },
    'cognita.backup.scrtracker.com': {
      environment: PRODUCTION,
      serverUri: `${protocol}//backup-api.scrtracker.com`,
    },
  };
  const result = lookup[host];
  if (!result) {
    console.log({
      protocol,
      host,
    });
    throw new Error(`Unable to locate environment for host [${host}]`);
  }
  return result;
}

export const environmentTypes = {
  DEVELOPMENT,
  STAGING,
  PRODUCTION,
};
export default getEnvironment;
