import React, { useState } from 'react';
import { authenticationService } from '../../services';

import Login from '@hogwarts/ui-components-login';

const LoginContainer = () => {
  const [loginState, setLoginState] = useState(
    authenticationService.requiresSSO() ? 'SSO' : 'LOG-IN'
  );
  const [error, setError] = useState(null);
  const [lastCredentials, setLastCredentials] = useState(null);

  return (
    <Login
      state={loginState}
      error={error}
      lastCredentials={lastCredentials}
      onLogin={async (credentials) => {
        setLoginState('LOGGING-IN');
        setError(null);
        setLastCredentials(credentials);
        const loginError = await authenticationService.login(credentials);
        if (loginError) {
          setError(loginError);
          setLoginState('LOG-IN');
        }
      }}
      onLogout={() => {
        authenticationService.logout();
      }}
    />
  );
};

export default LoginContainer;
