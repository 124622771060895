import { IconBar, Logo, TabBar } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UserMenu from './icons/user';
import styles from './styles.module.css';

const DEFAULT_HEADING_COLOR = '#048abb';

const HeaderContainer = () => {
  const { t } = useTranslation();

  // TODO: Logo OnClick

  return (
    <div className={cn(styles.header, 'px-2 px-lg-5')}>
      <div className="d-flex justify-content-between">
        <Logo />
        <TabBar
          brandColor={DEFAULT_HEADING_COLOR}
          tabs={[
            {
              title: t('Backups'),
              link: '/',
              exact: true,
            },
          ]}
        />
        <IconBar
          icons={[
            {
              component: UserMenu,
              title: t('User'),
              icon: 'user',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default HeaderContainer;
