import React from 'react';
import OrganisationItem from '../OrganisationItem';

import styles from './styles.module.css';

const OrganisationList = ({
  organisations,
  selectedOrganisation,
  onOrganisationSelected,
}) => {
  return (
    <div className={styles.organisationListContainer}>
      {organisations &&
        organisations.map((organisation) => (
          <OrganisationItem
            key={organisation.id}
            name={organisation.name}
            selected={organisation.id === selectedOrganisation?.id}
            onClick={onOrganisationSelected.bind(null, organisation)}
          />
        ))}
    </div>
  );
};

export default OrganisationList;
