import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Toaster } from '@blueprintjs/core';
import { getRequest } from '../../services';
import { UserContext } from '../../context';
import { Loading } from '@hogwarts/ui-components-core';
import Error from '../../components/Error';
import BackupDetails from '../../components/BackupDetails';

const BackupsContainer = ({ organisation }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [loadingData, setLoadingData] = useState(null);
  const [availableBackups, setAvailableBackups] = useState([]);
  const [dataError, setDataError] = useState(null);
  useEffect(() => {
    if (organisation?.id) {
      const organisationACL = userContext.organisations.find(
        (o) => o.id === organisation.id
      );
      if (organisationACL) {
        setIsUserAdmin(organisationACL.admin);
      }

      setLoadingData(true);
      getRequest().then((request) => {
        return request.get(`/backups/${organisation.id}`).then((result) => {
          if (result.status === 200) {
            const orderedBackups = result.data
              .map((backup) => {
                return {
                  createdAtDate: new Date(backup.createdAt),
                  ...backup,
                };
              })
              .sort((a, b) => {
                return b.createdAtDate - a.createdAtDate;
              });
            setAvailableBackups(orderedBackups);
            setLoadingData(false);
          } else {
            setLoadingData(false);
            setDataError(result.statusText);
          }
        });
      });
    }
  }, [organisation, userContext]);

  if (loadingData) return <Loading />;
  if (dataError) return <Error subHeading={dataError} />;

  return (
    <BackupDetails
      organisation={organisation}
      backups={availableBackups}
      isUserAdmin={isUserAdmin}
      onDownloadBackup={async (backupId) => {
        const toaster = Toaster.create({
          position: 'top',
        });
        const request = await getRequest();
        const result = await request.get(
          `/backups/${organisation.id}/${backupId}/download`
        );
        if (result.status === 200) {
          window.open(result.data.downloadUrl, '_blank');
          toaster.show({
            message: t('Backup Successfully Downloaded'),
            intent: 'success',
          });
        } else {
          toaster.show({
            message: t('Error Downloading Backup'),
            intent: 'danger',
          });
        }
      }}
      onDeleteBackup={async (backupId) => {
        const toaster = Toaster.create({
          position: 'top',
        });
        const request = await getRequest();
        const result = await request.delete(
          `/backups/${organisation.id}/${backupId}`
        );
        if (result.status === 200) {
          const newAvailableBackups = availableBackups.filter(
            (b) => b.id !== backupId
          );
          toaster.show({
            message: t('Backup Deleted'),
            intent: 'success',
          });
          setAvailableBackups(newAvailableBackups);
        } else {
          toaster.show({
            message: t('Error Deleting Backup'),
            intent: 'danger',
          });
        }
      }}
    />
  );
};

export default BackupsContainer;
