import React, { useEffect, useState, useContext } from 'react';
import { getRequest } from '../../services';
import { UserContext } from '../../context';
import { Loading } from '@hogwarts/ui-components-core';
import Error from '../../components/Error';
import OrganisationList from '../../components/OrganisationSelector/OrganisationList';
import NoOrganisations from '../../components/NoOrganisations';
import BackupsContainer from '../../containers/backups';

import styles from './styles.module.css';

const OrganisationsContainer = () => {
  const userContext = useContext(UserContext);
  const [loadingData, setLoadingData] = useState(null);
  const [availableOrganisations, setAvailableOrganisations] = useState([]);
  const [dataError, setDataError] = useState(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  useEffect(() => {
    setLoadingData(true);
    getRequest().then((request) => {
      return request.get('/access').then((result) => {
        if (result.status === 200) {
          const orderedOrganisation = result.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          setAvailableOrganisations(orderedOrganisation);
          setLoadingData(false);
        } else {
          setLoadingData(false);
          setDataError(result.statusText);
        }
      });
    });
  }, []);

  if (loadingData) return <Loading showLogo />;
  if (dataError) return <Error subHeading={dataError} />;

  if (userContext.organisations.length === 0) {
    return <NoOrganisations />;
  }

  return (
    <div className={styles.backupsContainer}>
      <div className={styles.listContainer}>
        <OrganisationList
          organisations={availableOrganisations}
          selectedOrganisation={selectedOrganisation}
          onOrganisationSelected={(organisation) =>
            setSelectedOrganisation(organisation)
          }
        />
      </div>
      <div className={styles.detailsContainer}>
        <BackupsContainer organisation={selectedOrganisation} />
      </div>
    </div>
  );
};

export default OrganisationsContainer;
