import React from 'react';
import { useTranslation } from 'react-i18next';
import { NonIdealState } from '@blueprintjs/core';

const Error = ({ heading, subHeading }) => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="warning-sign"
      title={heading ? heading : t('Error Loading Data')}
      description={subHeading}
    />
  );
};

export default Error;
