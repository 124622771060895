import OrganisationsContainer from '../containers/organisations';

const containerRoutes = [
  {
    path: '/',
    exact: true,
    component: OrganisationsContainer,
  },
];

export default containerRoutes;
