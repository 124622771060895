import React from 'react';
import cn from 'classnames';

import styles from './styles.module.css';

const OrganisationItem = ({ name, selected, onClick }) => {
  return (
    <div
      className={cn(styles.organisationItem, {
        [styles.selectedOrganisation]: selected,
      })}
      onClick={onClick}
    >
      <p className={styles.organisationItemHeader}>{name}</p>
    </div>
  );
};

export default OrganisationItem;
