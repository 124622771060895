import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  MenuItem,
  Popover,
  Position,
  MenuDivider,
} from '@blueprintjs/core';
import { authenticationService } from '../../../../services';
import { UserContext } from '../../../../context';

const UserMenu = ({ children }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const renderItems = () => (
    <Menu>
      <MenuDivider title={userContext.name} />
      <MenuDivider />
      <MenuItem
        icon="log-out"
        text={t('Sign Out')}
        onClick={() => {
          authenticationService.logout();
        }}
      />
    </Menu>
  );

  return (
    <Popover content={renderItems()} position={Position.BOTTOM}>
      {children}
    </Popover>
  );
};

export default UserMenu;
