import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card, Button } from '@blueprintjs/core';
import { DeletePopover } from '@hogwarts/ui-components-core';

import './styles.css';
import styles from './styles.module.css';

const BackupDetails = ({
  organisation,
  backups,
  isUserAdmin,
  onDownloadBackup,
  onDeleteBackup,
}) => {
  const { t } = useTranslation();

  if (!organisation) {
    return (
      <div className={styles.noSelection}>
        {t("Select an organisation on the left to view it's backups.")}
      </div>
    );
  }

  if (!backups || backups.length === 0) {
    return (
      <div className={styles.noSelection}>
        {t('No backups for this organisation.')}
      </div>
    );
  }

  return (
    <>
      {backups.map((backup) => (
        <Card key={backup.id} className={styles.backupCard}>
          <div className={styles.backupDetails}>
            <p>
              {t('Type')}
              {': '}
              <span className={styles.detailContent}>{backup.type}</span>
            </p>
            <p>
              {t('Created')}
              {': '}
              <span className={styles.detailContent}>{backup.createdAt}</span>
            </p>
          </div>
          <div
            className={cn('backupDetails__backupButtons', styles.backupButtons)}
          >
            <Button
              intent="primary"
              icon="import"
              onClick={onDownloadBackup?.bind(null, backup.id)}
            >
              {t('Download')}
            </Button>
            <DeletePopover
              buttonComponent={
                <Button
                  intent="danger"
                  style={{ width: '100%' }}
                  icon={isUserAdmin ? 'trash' : 'lock'}
                  disabled={!isUserAdmin}
                >
                  {t('Delete')}
                </Button>
              }
              message="Are you sure you want to remove this backup? This cannot be reversed."
              onRemove={onDeleteBackup?.bind(null, backup.id)}
            />
          </div>
        </Card>
      ))}
    </>
  );
};

export default BackupDetails;
