import React, { useMemo } from 'react';
// @ts-ignore
import blueLogo from '../../assets/logo/Blue-Background@4x.png';
// @ts-ignore
import styles from './styles.module.css';

interface LogoProps {
  logoSrc?: string;
  logoOverrideSrc?: string;
  LogoOverride?: React.FC<any>;
  overrideProps?: any;
  className?: string;
  onClick?: () => void;
}
const Logo = ({
  logoSrc,
  LogoOverride,
  logoOverrideSrc,
  className,
  onClick,
  overrideProps,
}: LogoProps) => {
  const LogoComponent = useMemo(() => {
    if (LogoOverride && logoOverrideSrc) {
      return (
        <LogoOverride
          className={styles.logo}
          src={logoOverrideSrc}
          noLoading
          {...overrideProps}
        />
      );
    }
    return <img className={styles.logo} src={logoSrc ?? blueLogo} alt="" />;
  }, [LogoOverride, logoOverrideSrc, logoSrc, overrideProps]);

  return (
    <div
      className={`d-flex flex-column align-items-start justify-content-center ${className}`}
      onClick={onClick}
    >
      {LogoComponent}
    </div>
  );
};

export default Logo;
