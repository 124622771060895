import { veryCleanObject } from '@hogwarts/utils';
import Auth0 from 'auth0-js';
import { DateTime } from 'luxon';
import { parseDomain, ParseResultListed } from 'parse-domain';
import getEnvironment from './getEnvironment';

const STORAGE_KEY = 'auth';
const IMPERSONATE_KEY = 'impersonate';

interface Credentials {
  email: string;
  password: string;
}
interface AuthResponseResult {
  expiresAt: number;
  token: string;
}
interface SecureMfaTokenResponse {
  token: string;
}
interface ResetRequestResponse {
  message: string;
  requestId: string;
  status: number;
}
interface ResetPasswordResponse {
  error?: string;
  status?: number;
}

let authConfig: any;
let auth: Auth0.WebAuth;
let lastAuthResponse: AuthResponseResult | null = null;
let requiresMfa = false;
let lastMfaResponse: SecureMfaTokenResponse | null = null;
const checkInitialised = () => {
  if (!auth) {
    throw new Error('Authentication service used before it was initialised.');
  }
};

const mapAuthResponse = (
  authResponse: Auth0.Auth0DecodedHash
): AuthResponseResult => {
  const { expiresIn, idToken } = authResponse;
  const expiresAt = DateTime.utc().plus({ seconds: expiresIn }).valueOf();
  const mappedResponse: AuthResponseResult = {
    expiresAt,
    token: idToken || '',
  };
  return mappedResponse;
};
const createAuthHeaders = (): Record<string, string> | null => {
  if (lastAuthResponse) {
    const impersonateKey = window.localStorage.getItem(IMPERSONATE_KEY);
    if (lastMfaResponse) {
      return veryCleanObject(
        {
          Authorization: `MfaBearer ${lastAuthResponse.token}/${
            lastMfaResponse.token
          } ${impersonateKey || ''}`.trim(),
        },
        { removeNull: true }
      );
    } else {
      return veryCleanObject(
        {
          Authorization: `Bearer ${lastAuthResponse.token} ${
            impersonateKey || ''
          }`.trim(),
        },
        { removeNull: true }
      );
    }
  } else {
    return null;
  }
};
const persist = () => {
  window.localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      lastAuthResponse,
      requiresMfa,
      lastMfaResponse,
    })
  );
};
const restore = () => {
  const storedAuthString = window.localStorage.getItem(STORAGE_KEY);
  if (storedAuthString) {
    const storedAuth = JSON.parse(storedAuthString);
    lastAuthResponse = storedAuth.lastAuthResponse;
    requiresMfa = storedAuth.requiresMfa;
    lastMfaResponse = storedAuth.lastMfaResponse;
  }
};
const checkAuthenticated = () => {
  return lastAuthResponse && (!requiresMfa || lastMfaResponse);
};
const logout = () => {
  lastAuthResponse = null;
  requiresMfa = false;
  lastMfaResponse = null;
  window.localStorage.removeItem(STORAGE_KEY);
  window.localStorage.removeItem(IMPERSONATE_KEY);
  auth.logout({ returnTo: window.location.origin });
};

const authenticationService = {
  initialise: (config: Record<string, any>) => {
    authConfig = {
      ...config.authentication,
      env: {
        domain: config.AUTH0_DOMAIN,
        clientID: config.AUTH0_CLIENT_ID,
      },
    };
    if (auth) {
      console.warn('Authentication service already initialised.');
    } else {
      auth = new Auth0.WebAuth({
        domain: authConfig.env.domain,
        clientID: authConfig.env.clientID,
        redirectUri: `${window.location.origin}/callback`,
        responseType: 'token id_token',
        scope: 'openid',
      });
    }
    restore();
  },
  isAuthenticated: () => {
    checkInitialised();
    return !!checkAuthenticated();
  },
  login: (credentials: Credentials) => {
    checkInitialised();
    return new Promise<string | undefined>((resolve) => {
      auth.login(
        {
          ...credentials,
          realm: authConfig.default,
        },
        (error) => {
          if (error) {
            console.error('Unable to login', error);
            if (error.code === 'request_error') {
              resolve('Unable to reach authentication service.');
            } else {
              resolve(error.description);
            }
          } else {
            resolve(undefined);
          }
        }
      );
    });
  },
  logout: () => {
    logout();
  },
  parseHash: async () => {
    checkInitialised();
    return new Promise((resolve, reject) => {
      auth.parseHash(async (error, authResponse) => {
        if (error) {
          console.error('Unable to parse authentication hash', error);
          reject('Unable to parse authentication hash');
        }
        if (authResponse) {
          const mappedResponse = mapAuthResponse(authResponse);
          lastAuthResponse = mappedResponse;

          // const uri = getEnvironment().serverUri;
          // const response = await fetch(`${uri}/api/v1/auth/verifyuser`, {
          //   method: 'GET',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     Authorization: `Bearer ${mappedResponse.token}`,
          //   },
          // });
          // const result = await response.json();
          // requiresMfa = result.status === 2;
          persist();
          resolve(true);
        }
      });
    });
  },
  impersonate(ticketId: string): void {
    if (!ticketId) {
      window.localStorage.removeItem(IMPERSONATE_KEY);
      return;
    }
    window.localStorage.setItem(IMPERSONATE_KEY, ticketId);
  },
  getAuthorisationHeaders: async (): Promise<Record<string, string> | null> => {
    checkInitialised();
    const authenticated = checkAuthenticated();
    if (!authenticated) {
      return Promise.resolve(null);
    }
    if (
      lastAuthResponse &&
      DateTime.fromMillis(lastAuthResponse.expiresAt) > DateTime.utc()
    ) {
      return Promise.resolve(createAuthHeaders());
    } else {
      return new Promise((resolve) => {
        // @ts-ignore - timeout not a property?
        return auth.checkSession({ timeout: 5000 }, (error, authResult) => {
          if (error) {
            lastAuthResponse = null;
            console.error('Unable to renew authentication', error);
            resolve(null);
          } else if (authResult) {
            const mappedResponse = mapAuthResponse(authResult);
            lastAuthResponse = mappedResponse;
            persist();
            resolve(createAuthHeaders());
          }
        });
      });
    }
  },
  requiresSSO: () => {
    const locationComponents = parseDomain(
      window.location.hostname
    ) as ParseResultListed;
    if (locationComponents && locationComponents.subDomains) {
      for (const option of Object.keys(authConfig.enterprise)) {
        if (locationComponents.subDomains.includes(option)) {
          return true;
        }
      }
    }
    return false;
  },
  checkSSO: () => {
    if (!checkAuthenticated()) {
      const locationComponents = parseDomain(
        window.location.hostname
      ) as ParseResultListed;
      if (locationComponents && locationComponents.subDomains) {
        for (const option of Object.keys(authConfig.enterprise)) {
          if (locationComponents.subDomains.includes(option)) {
            auth.authorize({
              connection: authConfig.enterprise[option],
            });
          }
        }
      }
    }
  },
  resetPasswordRequest: async (email: string): Promise<boolean> => {
    const uri = getEnvironment().serverUri;
    const response = await fetch(`${uri}/api/v1/auth/passwordresetrequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    return response.ok;
  },
  checkResetRequest: async (
    requestId: string
  ): Promise<ResetRequestResponse> => {
    const uri = getEnvironment().serverUri;
    const response = await fetch(
      `${uri}/api/v1/auth/passwordreset?id=${requestId}`,
      {
        method: 'GET',
      }
    );
    const result = await response.json();
    return result;
  },
  resetPassword: async (
    requestId: string,
    newPassword: string
  ): Promise<ResetPasswordResponse> => {
    const uri = getEnvironment().serverUri;
    const response = await fetch(`${uri}/api/v1/auth/passwordreset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: requestId, newPassword }),
    });
    const result = await response.json();
    return result;
  },
  requiresMfa: () => {
    return requiresMfa && !lastMfaResponse;
  },
  verifyMfaToken: async (mfaToken: string) => {
    try {
      const uri = getEnvironment().serverUri;
      const response = await fetch(`${uri}/api/v1/auth/verifymfa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${lastAuthResponse?.token}`,
        },
        body: JSON.stringify({
          authenticatorCode: mfaToken,
        }),
      });
      if (response.status === 200) {
        const secureMfaToken: SecureMfaTokenResponse = await response.json();
        if (secureMfaToken) {
          lastMfaResponse = secureMfaToken;
          persist();
          return true;
        }
      } else {
        const error = await response.text();
        if (error === 'Too many attempts') {
          logout();
        }
        return false;
      }
    } catch (error) {
      console.log('error', error);
      return false;
    }
  },
  mfaRegistered: (secureMfaToken: string) => {
    requiresMfa = true;
    lastMfaResponse = { token: secureMfaToken };
    persist();
  },
};

export default authenticationService;
