import axios, { AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import authenticationService from './authentication';
// import getEnvironment from './getEnvironment';

interface RequestOptions {
  headers?: Record<string, string>;
  retries?: number;
  timeout?: number;
  requireAuth?: boolean;
}

export const getRequest = async (
  options: RequestOptions
): Promise<AxiosInstance> => {
  const {
    headers,
    retries = 1,
    timeout = 5000,
    requireAuth = true,
  } = options || {};
  // const baseUri = getEnvironment().serverUri;
  let authenticationHeaders;
  if (requireAuth) {
    authenticationHeaders =
      await authenticationService.getAuthorisationHeaders();
  }
  const client = axios.create({
    headers: { ...headers, ...authenticationHeaders },
    // baseURL: `${baseUri}/api/v1`,
    baseURL: process.env.REACT_APP_API_BASE,
    timeout,
  });
  if (retries > 1) {
    axiosRetry(client, {
      retries,
      shouldResetTimeout: true,
      retryDelay: () => {
        return 500;
      },
    });
  }
  return client;
};

export const request = async <T = any>(
  url: string,
  options: RequestOptions & { method?: string }
): Promise<AxiosResponse<T>> => {
  const { method = 'get', ...requestOptions } = options || {};
  const request = await getRequest(requestOptions);
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }
  switch (method.toLowerCase()) {
    case 'get':
      return request.get(url);
    case 'post':
      return request.post(url);
    default: {
      throw new Error(`Unsupported method [${method}]`);
    }
  }
};
